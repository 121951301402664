import request from '@/axios/index';

//账号密码登录  旧：/api/pc/v1/mobile/password/login   新：/api/pc/v1/mobile/password/h5/login
export function mobile_password(mobile,password) {
    return request({
        url: '/api/pc/v1/mobile/password/h5/login?mobile='+mobile+"&password="+password,
        method: 'post',
    })
}

//验证码登录  /api/pc/v1/smscode/login  /api/frame/v1/app/smscode/H5/login
export function smscode_login(mobile,smsCode) {
    return request({
        url: '/api/frame/v1/app/smscode/H5/login?mobile='+mobile+"&smsCode="+smsCode,
        method: 'post',
    })
}


 //发送短信验证码
export function mobile_send(query) {
    return request({
        url: '/api/frame/v1/common/smscode/send?mobile='+query.mobile+"&type="+query.type,
        method: 'post'
    })
}

//用户信息 
export function getuserinfo() {
    return request({
        url: "/api/app/v1/mine/index",
        method: 'post',
    })
}

//修改密码
export function setnewpassword(mobile,smsCode,newPassword) {
    return request({
        url: "/api/frame/v1/customer/forgetpassword/setnewpassword?mobile="+mobile+"&smsCode="+smsCode+"&newPassword="+newPassword,
        method: 'post',
    })
}

//获取课程类型
export function getTypeList() {
    return request({
        // url: "/api/app/v1/index/course/type/list",
        url: "/api/app/v1/index/course/type/drainagelist",
        method: 'post',
    })
}

//获取福利课程
export function getPackageList(data) {
    return request({
        url: "/api/app/v1/course/package/list",
        method: 'post',
        params: data
    })
}

//获取福利课程
export function setReceive(data) {
    return request({
        // url: "/api/app/v1/course/package/receive/course?coursePackageId="+data,
        url: "/api/app/v1/course/package/receive/course/",
        method: 'post',
        data: data
    })
}


