<template>
<div class="retpassword">
    <van-nav-bar
    title="找回密码"
    left-text=""
    right-text=""
    left-arrow
    @click-left="onClickLeft"
    />
    <div class="login-box">
    
    <div class="login-box-title">
        <span>为了您的账号安全，请绑定您的手机号</span>
    </div>
    <div class="input-box"> 
        <input v-model="mobile" placeholder="请输入您的手机号码">
    </div>
    <div class="f input-box"> 
        <input v-model="smsCode" placeholder="请输入验证码" class="f-auto">
        <span class="btn-code" :class="{disabled:isCountDown}" @click="handleSend">{{codeText}}</span>
    </div>
    <div class="input-box"> 
        <input v-model="password" type="password" placeholder="请输入新密码">
    </div>

    <div class="input-box"> 
        <input v-model="newpassword" type="password" placeholder="再次确认新密码">
    </div>


    
    
   
    
    <div class="btn-submit" @click="onSubmit">立即修改</div>
    
   
    
  </div>
</div>
  
</template>

<script>
import { mobile_send,setnewpassword,getuserinfo} from "@/api/login.js";

import { wx_getOpenId,wx_getCode,wx_login} from "@/api/wxLogin.js";
export default {
  data() {
      return {
        mobile:"",  //13811362165    18410272572
        password:"",
        newpassword:"",
        isCountDown:true,
        codeText:"获取验证码",
        smsCode:"",
        regMobile:/^1[3456789]\d{9}$/,
      }
    },
  name: 'retpassword',
  created(){
    

  },
  methods: {
    getPassWord(){
        
    },
    userInfo(){
      getuserinfo().then((res)=>{
        if(res.data.code == 0){
          localStorage.setItem("userInfo", JSON.stringify(res.data))
        }
        
      })
    },
    onClickLeft(){
        this.$router.go(-1);
    },
    onSubmit(){
      let errHint = ''
      if(!this.regMobile.test(this.mobile)){
        errHint = this.mobile?'手机号格式有误':'请填写手机号';
        this.$toast(errHint);
        return
      }
      if(!this.smsCode){
        errHint = '请输入验证码'
        this.$toast(errHint);
        return
      }
      if(!this.password){
        errHint = '请输入新密码'
        this.$toast(errHint);
        return
      }
      if(!this.newpassword){
        errHint = '请输入确认密码'
        this.$toast(errHint);
        return
      }
      if(this.newpassword != this.password){   
        errHint = '两次密码不一致'
        this.$toast(errHint);
        return
      }
      setnewpassword(this.mobile,this.smsCode,this.newpassword).then((res)=>{
          if(res.data.code == 0){
            
            this.$router.push("/");
          }else{
            this.$toast(res.data.msg);
          }
          

        })

    },
    handleSend(){
      if (this.regMobile.test(this.mobile)) {
        mobile_send({
          mobile: this.mobile,
          type: 2
        }).then(res => {
          this.isCountDown = true
          this.countDown(60)
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$toast(this.mobile?'手机号格式有误':'请填写手机号');
      }

    },
    handleAgreement(){},
    countDown(num){
      if (num > 0) {
        this.codeText = `${num}秒后重发`
        num--
        setTimeout(() => {
          this.countDown(num)
        }, 1000);
      } else {
        this.isCountDown = false;
        this.codeText = '重发发送';
      }
    },

       
  },
  
  mounted () {
         
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less"  scoped>

body {
  background-color: #fff;
}
.login-box{
  padding: 0 33px;
}
.retpassword{
    /deep/ .van-hairline--bottom::after{
        border-bottom-width:0px
    }
}
.login-box-title{
    width: 100%;
    height:80px;
    line-height: 80px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}
.login-gourl{
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: space-between;

}
.logo-continer{
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.switch-margin-right{
  margin-right: 40px;
  color: #888888;
  margin-bottom: .4rem;
}
.switch-margin-left{
  margin-left: 40px;
  color: #333;
  margin-bottom: .4rem;
}
.wx-loge{
  width:32px;
  height: 32px;
}
.logo {
  font-weight: 500;
  font-size: 16px;
  color: #141414;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 40px;
  padding-top: 75px;
  background: url(../../assets/images/logo.png) no-repeat center top;
  background-size: 68px;
}
.switch {
  color: #333;
  margin-bottom: .4rem;
  margin-left: .15rem;
}
.input-box{
  line-height: 22px;
  border-radius: 22px;
  margin-bottom: 20px;
  padding: 10px 26px;
  border: 1px solid #ccc;
}
.btn-code{
  color: #5D7DFF;
}
.btn-submit{
  color: #fff;
  font-size: 17px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  background-color: #5D7DFF;
  margin-top: 10px;
}
.agree {
  width: 100%;
  position: fixed;
  bottom: 15px;
  font-size: 14px;
  margin-left: -20px;
  color: #666;
}
.agreement {
  padding: 10px;
  height: 70vh;
  overflow: auto;
}
</style>
