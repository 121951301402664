import request from '@/axios/index';



//微信登录 type 1注册 0登录  
export function wx_login(openId,type) {
    return request({
        url: '/api/frame/v1/app/third/wx/login?openId='+openId+"&type="+type,
        method: 'post',
    })
}

//微信获取code 
export function wx_getCode(userId) {
    return request({
        url: '/api/h5/v1/wechat/get/wechat/official/account/code?userId='+userId,
        method: 'post',
    })
}

//微信获取code 
export function wx_getOpenId(userId,code) {
    return request({
        url: '/api/h5/v1/wechat/get/wechat/official/account/code?userId='+userId+"&code="+code,
        method: 'post',
    })
}